const FALLBACK_LNG = 'uk';
const SUPPORTED_LNGS =  ['uk', 'en', 'ar'];
const SUPPORTED_LNGS_NAMES_MAP = {
	'uk': 'ua',
	'en': 'en',
	'ar': 'ar'
};

export {
	FALLBACK_LNG,
	SUPPORTED_LNGS,
	SUPPORTED_LNGS_NAMES_MAP
}