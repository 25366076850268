import { Box, Link, Stack, Typography, Button } from "@mui/material"
import { ThemeProvider } from '@mui/material/styles';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useTranslation } from 'react-i18next';

import NavLink from "../NavLink/NavLink";
import { SUPPORTED_LNGS, FALLBACK_LNG, SUPPORTED_LNGS_NAMES_MAP } from "../../config/i18nConfig";

import logoWhite from "../../img/logo_white.svg";
import theme from "../../styles/theme";
import { useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const currentPath = location.pathname;
	const { t, i18n } = useTranslation();
	const isArabic = i18n.language === 'ar';

	const changeLanguage = (lang) => () => {
		for (let supportedLang of SUPPORTED_LNGS) {
			if (currentPath === `/${supportedLang}` || currentPath.startsWith(`/${supportedLang}/`)) {
				navigate(currentPath.replace(`/${supportedLang}`, lang === FALLBACK_LNG ? '' : `/${lang}`), { replace: true });
				i18n.changeLanguage(lang);
				return;
			}
		}

		navigate(`/${lang === FALLBACK_LNG ? '' : lang}${currentPath}`, { replace: true });
		i18n.changeLanguage(lang);
	}

	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					display: "flex",
					flexDirection: isArabic ? "row-reverse" : "row",
					justifyContent: "space-between",
					flexWrap: "wrap",
					bgcolor: "#000",
					color: "#fff",
					m: { xs: "8px 16px", sm: "8px 32px" },
					borderRadius: 4,
					p: {
						xs: "32px 16px",
						sm: "48px 24px"
					},
					gap: "80px 56px"
				}}
			>
				<Stack
					alignItems={isArabic ? "end" : "start"}
					justifyContent="space-between"
					sx={{ width: 400 }}
				>
					<Stack
						direction={ isArabic ? "row-reverse" : "row" }
						alignItems="center"
						spacing={1}
						sx={{ mb: 10 }}
					>
						<img src={logoWhite} width="48px" height="48px" alt="Zayed logo" />
						<Box>
							<Typography
								lang={i18n.language}
								dir={ isArabic ? "rtl" : "ltr" }
								fontSize={12}
								lineHeight={1.2}
								fontWeight={100}
							>{ t("Charity Foundation") }</Typography>
							<Typography fontSize={24} lineHeight={1.2} fontWeight={100}>Zayed</Typography>
						</Box>
					</Stack>
					<Typography
						lang={i18n.language}
						dir={ isArabic ? "rtl" : "ltr" }
						fontSize={24}
						lineHeight={1.2}
						fontWeight={400}
					>{ t("Slogan of the Zayed Charitable Foundation") }</Typography>
				</Stack>
				<Box
					sx={{
						display: "grid",
						justifyItems: isArabic ? "right" : "left",
						gridTemplateColumns: "1fr 1fr",
						gap: "32px 16px"
					}}
				>
					<NavLink href="#">{ t("News") }</NavLink>
					<NavLink href="#">{ t("Help Civilians") }</NavLink>
					<NavLink href="#">{ t("Projects") }</NavLink>
					<NavLink href="#">{ t("About Foundation") }</NavLink>
					<NavLink href="#">{ t("Reports") }</NavLink>
					<NavLink href="#">{ t("Partners") }</NavLink>
					<NavLink href="#">{ t("FAQ") }</NavLink>
				</Box>
				<Stack sx={{ width: 400 }}>
					<Stack
						direction={ isArabic ? "row-reverse" : "row" }
						justifyContent="space-between"
						spacing={2}
						sx={{ mb: 5 }}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: isArabic ? "end" : "start"
							}}
						>
							<Typography fontSize={14} fontWeight={300} sx={{ mb: 1, color: "#aab2ca" }}>{ t("Language") }</Typography>
							<Stack
								direction={ isArabic ? "row-reverse" : "row" }
								alignItems="center"
								spacing={1.5}
								sx={{
									height: 56,
									p: 2,
									border: "1px solid #fff",
									borderRadius: 7
								}}
							>
								{SUPPORTED_LNGS.map((lang) => (
									<Box
										key={lang}
										onClick={changeLanguage(lang)}
										className={i18n.language === lang ? "active" : null}
										sx={{
											opacity: 0.5,
											fontWeight: 400,
											textTransform: "uppercase",
											transition: "all 0.3s ease-in-out",
											cursor: "pointer",
											"&.active": {
												opacity: 1
											},
											"&:not(.active):hover": {
												opacity: 0.5,
												color: "#171c8f"
											},
										}}
									>{ SUPPORTED_LNGS_NAMES_MAP[lang] }</Box>
								))}
							</Stack>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: isArabic ? "end" : "start"
							}}
						>
							<Typography fontSize={14} fontWeight={300} sx={{ mb: 1, color: "#aab2ca" }}>{ t("Foundation page") }</Typography>
							<Stack
								direction={ isArabic ? "row-reverse" : "row" }
								alignItems="center"
								spacing={1}
							>
								<Link
									href="#"
									sx={{
										height: 56,
										width: 56,
										borderRadius: 7,
										border: "1px solid #fff",
										color: "#fff",
										display: "grid",
										placeItems: "center",
										overflow: "hidden",
										transition: "all 0.3s ease-in-out",
										cursor: "pointer",
										"&:hover": {
											border: "none",
											background: "radial-gradient(43.75% 43.75% at 0 89.58%,#ff6b00 0,rgba(255,122,0,0) 100%),radial-gradient(108.33% 108.33% at 31.25% -8.33%,#0057ff 0,rgba(175,0,255,0) 100%),radial-gradient(32.29% 32.29% at 34.38% 96.88%,#ffe24a 0,#da2f7c 100%)"
										}
									}}
								>
									<InstagramIcon sx={{ fontSize: "32px" }} />
								</Link>
								<Link
									href="#"
									sx={{
										height: 56,
										width: 56,
										borderRadius: 7,
										border: "1px solid #fff",
										color: "#fff",
										display: "grid",
										placeItems: "center",
										overflow: "hidden",
										transition: "all 0.3s ease-in-out",
										cursor: "pointer",
										"&:hover": {
											border: "none",
											backgroundColor: "#1877f2"
										}
									}}
								>
									<FacebookIcon sx={{ fontSize: "32px" }} />
								</Link>
							</Stack>
						</Box>
					</Stack>
					<Stack>
						<Link underline='none' href="tel:0000000000" sx={{ mb: 1 }}>
							<Button
								variant="outlined"
								disableElevation={true}
								sx={{
									height: 56,
									borderRadius: 7,
									px: 3,
									gap: 2,
									fontFamily: "e-Ukraine",
									fontSize: 16,
									textTransform: "none",
									width: "100%",
									flexDirection: isArabic ? "row-reverse" : "row",
									justifyContent: "space-between",
									color: "#fff",
									borderColor: "#fff",
									"&:hover": {
										borderColor: "#fff",
										color: "#171c8f"
									}
								}}
							>
								<Stack alignItems={ isArabic ? "end" : "start" }>
									<Typography fontSize={10} fontWeight={300} lineHeight={1} sx={{ mr: 1, color: "#fff" }}>{ t("Hotline") }:</Typography>
									<Typography lineHeight={1} mt={1}>00000000000</Typography>
								</Stack>
								<CallIcon />
							</Button>
						</Link>
						<Link underline='none' href="mailto:info@zayed.com.ua" sx={{ mb: 1 }}>
							<Button
								variant="outlined"
								disableElevation={true}
								sx={{
									height: 56,
									borderRadius: 7,
									px: 3,
									gap: 2,
									fontFamily: "e-Ukraine",
									fontSize: 16,
									textTransform: "none",
									width: "100%",
									flexDirection: isArabic ? "row-reverse" : "row",
									justifyContent: "space-between",
									color: "#fff",
									borderColor: "#fff",
									"&:hover": {
										borderColor: "#fff",
										color: "#171c8f"
									}
								}}
							>
								<Stack alignItems={ isArabic ? "end" : "start" }>
									<Typography fontSize={10} fontWeight={300} lineHeight={1} sx={{ mr: 1, color: "#fff" }}>{ t("Contact us") }:</Typography>
									<Typography lineHeight={1} mt={1}>info@zayed.com.ua</Typography>
								</Stack>
								<MailIcon />
							</Button>
						</Link>
						<Button
							variant="contained"
							sx={{
								height: { xs: 36, sm: 56 },
								borderRadius: { xs: 4.5, sm: 7 },
								px: { xs: 2, sm: 3 },
								flexDirection: isArabic ? "row-reverse" : "row",
								justifyContent: "space-between",
								gap: 2,
								fontFamily: "e-Ukraine",
								fontSize: "inherit",
								textTransform: "none",
							}}
						>
							{ t("Donate") }
							<ArrowOutwardIcon sx={{ fontSize: "28px", display: { xs: "none", sm: "block" } }} />
						</Button>
					</Stack>
				</Stack>
			</Box>
		</ThemeProvider>
	)
}

export default Footer