import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import NotFound from "./pages/NotFound/NotFound";
import { FALLBACK_LNG, SUPPORTED_LNGS } from './config/i18nConfig';

function multilangRoute(path, component) {
	return [
		<Route path={path} element={component} />,
		...(SUPPORTED_LNGS.map(lang => ([
			<Route path={`/${lang}${path}`} element={lang === FALLBACK_LNG ? <Navigate to={path} /> : component} />
		])))
	];
}

function App() {
	return (
		<>
			<Header />
			<Routes>
				{ multilangRoute("/", <Home />) }

				<Route path="*" element={<NotFound />} />
			</Routes>
			<Footer />
		</>
	);
}

export default App;
