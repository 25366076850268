import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { FALLBACK_LNG, SUPPORTED_LNGS } from './config/i18nConfig';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: FALLBACK_LNG,
		supportedLngs: SUPPORTED_LNGS,
		debug: false,
		detection: {
			order: ['path', 'navigator']
		},
		interpolation: {
			escapeValue: false,
		}
	});


export default i18n;