import { createTheme } from '@mui/material/styles';

export default createTheme({
	palette: {
		primary: {
			main: '#171c8f',
			light: '#2a30c7',
			dark: '#080d78',
			contrastText: '#fff',
		},
		primaryTransparent: {
			main: "#171c8f11",
			light: '#2a30c722',
			dark: '#080d7822',
			contrastText: '#111',
		}
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1440,
			xxl: 1540
		},
	},
	typography: {
		fontFamily: "inherit"
	}
});