import {Box, Container, Typography} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import theme from '../../styles/theme';
import logo from '../../img/logo.svg';
import { useTranslation } from 'react-i18next';
import UkraineMap from '../../components/UkraineMap/UkraineMap';

function Home() {
	const { t, i18n } = useTranslation();
	const isArabic = i18n.language === 'ar';

	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					background: 'linear-gradient(#56b46c, #fff)',
					border: "1px solid #aab2ca",
					borderRadius: 4,
					m: { xs: "8px 16px", sm: "8px 32px" }
				}}
			>
				<Container maxWidth="xxl">
					<Box sx={{ m: "0 auto", width: "100%", maxWidth: 520, py: 3  }}>
						<img src={logo} alt="Zayed logo" />
					</Box>
				</Container>
			</Box>
			<Box
				sx={{
					background: 'linear-gradient(#56b46c, #fff)',
					border: "1px solid #aab2ca",
					borderRadius: 4,
					m: { xs: "8px 16px", sm: "8px 32px" }
				}}
			>
				<Container maxWidth="xxl">
					<Box sx={{ m: "0 auto", py: 3  }}>
						<Typography
							lang={i18n.language}
							dir={ isArabic ? "rtl" : "ltr" }
							textAlign="center"
							fontFamily="e-UkraineHead"
							fontSize={{ xs: 36, sm: 44, lg: 66, xl: 88 }}
							fontWeight={300}
							lineHeight={1.2}
							sx={{
								letterSpacing: { xs: -2, xl: -4 },
								my: { xs: 3, sm: 5 }
							}}
						>{ t("Website in development") }</Typography>
					</Box>
				</Container>
			</Box>
			<Box sx={{ m: { xs: "8px 16px", sm: "8px 32px" } }}>
				<Container maxWidth="xl">
					<Box xs={{ m: "0 auto", }}>
						<UkraineMap />
					</Box>
				</Container>
			</Box>
		</ThemeProvider>
	);
}

export default Home