import {Box, Container, Stack, Typography} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import theme from '../../styles/theme';

const NotFound = () => {
	const { t, i18n } = useTranslation();
	const isArabic = i18n.language === 'ar';

	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					background: 'linear-gradient(#56b46c, #fff)',
					border: "1px solid #aab2ca",
					borderRadius: 4,
					m: { xs: "8px 16px", sm: "8px 32px" }
				}}
			>
				<Container maxWidth="xxl">
					<Stack alignItems={ isArabic ? "end" : "start" } my={{ xs: 4, sm: 8 }}>
						<Box sx={{
							pb: 4,
							mb: 4,
							borderBottom: "1px solid #000",
							display: "flex",
							flexDirection: "column",
							width: "100%" }}
						>
							<Typography
								lang={i18n.language}
								dir={ isArabic ? "rtl" : "ltr" }
								fontFamily="e-UkraineHead"
								fontSize={{ xs: 40, sm: 66, xl: 88 }}
								fontWeight={300}
								lineHeight={1.2}
								sx={{
									letterSpacing: { xs: -2, xl: -4 },
									alignSelf: isArabic ? "end" : "start",
									maxWidth: { xs: 320, sm: 640, lg: 800 }
								}}
							>{ t("404_title") }</Typography>
						</Box>
						<Typography
							lang={i18n.language}
							dir={ isArabic ? "rtl" : "ltr" }
							fontSize={{ xs: 16, sm: 24 }}
							sx={{ maxWidth: { xs: 320, sm: 640, lg: 800 } }}
						>{ t("404_text") }</Typography>
					</Stack>
				</Container>
			</Box>
		</ThemeProvider>
	);
}

export default NotFound