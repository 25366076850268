import { useState, Suspense } from "react";
import { AppBar, Container, Stack, Box, Link, Button, Typography, IconButton, List, Drawer, ListItemText } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import NavLink from "../NavLink/NavLink";
import { SUPPORTED_LNGS, FALLBACK_LNG, SUPPORTED_LNGS_NAMES_MAP } from "../../config/i18nConfig";

import theme from '../../styles/theme';
import logo from '../../img/logo.svg';
import { useLocation, useNavigate } from "react-router-dom";

const MenuListItem = (props) => <ListItemText sx={{ "& .MuiTypography-root": { fontFamily: "inherit !important", fontWeight: "inherit !important" }, ...props.sx }}>{props.children}</ListItemText>

function Header() {
	const navigate = useNavigate();
	const location = useLocation();
	const currentPath = location.pathname;
	const { t, i18n } = useTranslation();
	const isArabic = i18n.language === 'ar';
	const [drawerOpened, setDrawerOpened] = useState(false);
	const xlMediaQuery = useMediaQuery('(max-width: 1439px)');

	const toggleDrawer = (newDrawerOpened) => () => {
		setDrawerOpened(newDrawerOpened);
	};

	const changeLanguage = (lang) => () => {

		for (let supportedLang of SUPPORTED_LNGS) {
			if (currentPath === `/${supportedLang}` || currentPath.startsWith(`/${supportedLang}/`)) {
				navigate(currentPath.replace(`/${supportedLang}`, lang === FALLBACK_LNG ? '' : `/${lang}`), { replace: true });
				i18n.changeLanguage(lang);
				return;
			}
		}

		navigate(`/${lang === FALLBACK_LNG ? '' : lang}${currentPath}`, { replace: true });
		i18n.changeLanguage(lang);
	}

	return (
		<Suspense fallback="loading">
			<ThemeProvider theme={theme}>
				<AppBar position='sticky' elevation={0} sx={{ bgcolor: "#fff", height: { xs: 56, sm: 72 }, color: "#111" }}>
					<Container maxWidth="xxl" sx={{ height: "100%" }}>
						<Stack direction={ isArabic ? "row-reverse" : "row" } alignItems="center" justifyContent="space-between" sx={{ height: "100%" }}>
							<Stack direction={ isArabic ? "row-reverse" : "row" } alignItems="center" gap={{ xs: 1, sm: 4 }}>
								<Box sx={{ width: { xs: 36, sm: 48 } }}>
									<Link href="/">
										<img src={logo} alt="Zayed logo" style={{ display: 'block', height: "100%", width: "100%" }} />
									</Link>
								</Box>
								<Stack direction={ isArabic ? "row-reverse" : "row" } gap={2} alignItems="center">
									<NavLink href="#" sx={{ display: { xs: "none", md: "block" } }}>{ t("News") }</NavLink>
									<NavLink href="#" sx={{ display: { xs: "none", md: "block" } }}>{ t("Help Civilians") }</NavLink>
									<NavLink href="#" sx={{ display: { xs: "none", xl: "block" } }}>{ t("Projects") }</NavLink>
									<NavLink href="#" sx={{ display: { xs: "none", xl: "block" } }}>{ t("About Foundation") }</NavLink>
									<NavLink href="#" sx={{ display: { xs: "none", xl: "block" } }}>{ t("Reports") }</NavLink>
									<NavLink href="#" sx={{ display: { xs: "none", xl: "block" } }}>{ t("Partners") }</NavLink>
									<NavLink href="#" sx={{ display: { xs: "none", xl: "block" } }}>{ t("FAQ") }</NavLink>
								</Stack>
							</Stack>
							<Stack direction={ isArabic ? "row-reverse" : "row" } sx={{ gap: 1 }} alignItems="center">
								<Box sx={{
									height: { xs: 36, sm: 56 },
									width: 56,
								}}>
									<Box sx={{
										display: "flex",
										flexDirection: "column",
										height: { xs: 36, sm: 56 },
										borderRadius: { xs: 4.5, sm: 7 },
										width: 56,
										overflow: "hidden",
										transition: "height .1s ease-in-out",
										backgroundColor: "#fff",
										"&:hover": {
											height: { xs: 36*SUPPORTED_LNGS.length, sm: 56*SUPPORTED_LNGS.length },
										}
									}}>
										{SUPPORTED_LNGS.map((lang) => (
											<Box
												key={lang}
												onClick={changeLanguage(lang)}
												className={i18n.language === lang ? "active" : null}
												sx={{
													flexShrink: 0,
													display: 'grid',
													placeItems: 'center',
													height: { xs: 36, sm: 56 },
													width: 56,
													borderRadius: { xs: 4.5, sm: 7 },
													bgcolor: "#171c8f11",
													textTransform: "uppercase",
													transition: "background-color 0.3s ease-in-out",
													cursor: "pointer",
													"&.active": {
														order: -1
													},
													"&:not(.active):hover": {
														backgroundColor: "#171c8f",
														color: "#fff"
													}
											}}
											>
												{ SUPPORTED_LNGS_NAMES_MAP[lang] }
											</Box>
										))}
									</Box>
								</Box>
								<Link underline='none' href="mailto:info@zayed.com.ua" sx={{ display: { xs: "none", xl: "flex" } }}>
									<Button
										variant="contained"
										color="primaryTransparent"
										disableElevation={true}
										sx={{
											height: 56,
											minWidth: 240,
											display: "flex",
											flexDirection: isArabic ? "row-reverse" : "row",
											justifyContent: "space-between",
											borderRadius: 7,
											px: 3,
											gap: 2,
											fontFamily: "e-Ukraine",
											fontSize: 16,
											textTransform: "none"
										}}
									>
										<Stack alignItems={ isArabic ? "end" : "start" }>
											<Typography fontSize={10} fontWeight={300} lineHeight={1} sx={{ color: "#4e5362" }}>{ t("Contact us") }</Typography>
											<Typography lineHeight={1} mt={1}>info@zayed.com.ua</Typography>
										</Stack>
										<MailIcon />
									</Button>
								</Link>
								<Button
									variant="contained"
									sx={{
										height: { xs: 36, sm: 56 },
										minWidth: { xs: "none", sm: 240},
										display: "flex",
										flexDirection: isArabic ? "row-reverse" : "row",
										justifyContent: "space-between",
										borderRadius: { xs: 4.5, sm: 7 },
										px: { xs: 2, sm: 3 },
										gap: 2,
										fontFamily: "e-Ukraine",
										fontSize: "inherit",
										textTransform: "none"
									}}
								>
									{ t("Donate") }
									<ArrowOutwardIcon sx={{ fontSize: "28px", display: { xs: "none", sm: "block" } }} />
								</Button>
								<IconButton
									onClick={toggleDrawer(!drawerOpened)}
									sx={{
										height: { xs: 36, sm: 56 },
										width: 56,
										borderRadius: { xs: 4.5, sm: 7 },
										backgroundColor: "#171c8f11",
										"&:hover": {
											backgroundColor: "#080d7822",
										},
										display: { xs: "grid", xl: "none" }
									}}
								>
									{drawerOpened ? <CloseIcon sx={{ color: "#111" }} /> : <MenuIcon sx={{ color: "#111" }} />}
								</IconButton>
								<Drawer
									anchor={ isArabic ? "left" : "right" }
									open={drawerOpened && xlMediaQuery}
									onClose={toggleDrawer(false)}
									elevation={0}
									sx={{
										"& .MuiDrawer-paper": {
											mt: { xs: 7, sm: 9 },
											p: 3,
											width: {
												xs: "100%",
												sm: 360
											}
										},
										zIndex: 1000
									}}
									hideBackdrop
								>
									<Stack
										sx={{
											height: { xs: 'calc(100% - 56px)', sm: 'calc(100% - 76px)' },
											width: "100%",
											maxWidth: 312,
											margin: "0 auto",
											justifyContent: { xs: "flex-start", sm: "space-between" }
										}}
									>
										<List
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: isArabic ? "end" : "start",
												gap: 2,
												mb: 4
											}}
										>
											<MenuListItem sx={{ display: { xs: "block", md: "none" } }}>
												<NavLink href="#">{ t("News") }</NavLink>	
											</MenuListItem>
											<MenuListItem sx={{ display: { xs: "block", md: "none" } }}>
												<NavLink href="#">{ t("Help Civilians") }</NavLink>
											</MenuListItem>
											<MenuListItem>
												<NavLink href="#">{ t("Projects") }</NavLink>
											</MenuListItem>
											<MenuListItem>
												<NavLink href="#">{ t("About Foundation") }</NavLink>
											</MenuListItem>
											<MenuListItem>
												<NavLink href="#">{ t("Reports") }</NavLink>
											</MenuListItem>
											<MenuListItem>
												<NavLink href="#">{ t("Partners") }</NavLink>
											</MenuListItem>
											<MenuListItem>
												<NavLink href="#">{ t("FAQ") }</NavLink>
											</MenuListItem>
										</List>
										<Stack gap={1}>
											<Link underline='none' href="tel:0000000000">
												<Button
													variant="contained"
													color="primaryTransparent"
													disableElevation={true}
													sx={{
														height: 56,
														borderRadius: 7,
														px: 3,
														gap: 2,
														fontFamily: "e-Ukraine",
														fontSize: 16,
														textTransform: "none",
														width: "100%",
														flexDirection: isArabic ? "row-reverse" : "row",
														justifyContent: "space-between"
													}}
												>
													<Stack alignItems={ isArabic ? "end" : "start" }>
														<Typography fontSize={10} fontWeight={300} lineHeight={1} sx={{ color: "#4e5362" }}>{ t("Hotline") }</Typography>
														<Typography lineHeight={1} mt={1}>00000000000</Typography>
													</Stack>
													<CallIcon />
												</Button>
											</Link>
											<Link underline='none' href="mailto:info@zayed.com.ua">
												<Button
													variant="contained"
													color="primaryTransparent"
													disableElevation={true}
													sx={{
														height: 56,
														borderRadius: 7,
														px: 3,
														gap: 2,
														fontFamily: "e-Ukraine",
														fontSize: 16,
														textTransform: "none",
														width: "100%",
														flexDirection: isArabic ? "row-reverse" : "row",
														justifyContent: "space-between"
													}}
												>
													<Stack alignItems={ isArabic ? "end" : "start" }>
														<Typography fontSize={10} fontWeight={300} lineHeight={1} sx={{ color: "#4e5362" }}>{ t("Contact us") }</Typography>
														<Typography lineHeight={1} mt={1}>info@zayed.com.ua</Typography>
													</Stack>
													<MailIcon />
												</Button>
											</Link>
										</Stack>
									</Stack>
								</Drawer>
							</Stack>
						</Stack>
					</Container>
				</AppBar>
			</ThemeProvider>
		</Suspense>
	)
}

export default Header